import React, {
  useCallback,
  useEffect,
  useState,
} from "react";
import { Link, NavLink } from "react-router-dom";
import logoHogar from "../../../assets/logos/programs/hogar-del-nino.svg";


export const Navbar = () => {

  const [darkNavbar, setDarkNavbar] = useState();
  const [scrolly, setScrolly] = useState();
  const [scrollUpDown, setScrollUpDown] = useState(0);
  const [screenWidth, setScreenWidth] = useState();
  const [showNav, setShowNav] = useState("");

  const [expanded, setExpanded] = useState("navbar-collapse collapse");

  const handleExpanded = () => {
    setExpanded("navbar-collapse collapse");
  };

  const handleNavigation = useCallback(() => {
    if (scrolly > 200) {
      if (scrollUpDown > scrolly + 10) {
        setShowNav("animate__animated animate__slideInDown");
      } else if (scrollUpDown + 10 < scrolly) {
        setShowNav("animate__animated animate__slideOutUp");
      }
    }
    setScrollUpDown(scrolly);

  }, [scrolly,scrollUpDown]);

  window.onscroll = () => {
    setScrolly(window.scrollY);
  };

  window.onresize = () => {
    setScreenWidth(window.outerWidth);
  };

  useEffect(() => {
    if (screenWidth < 950) {
      setDarkNavbar("navbar__bg-nav-in shadow");
      handleNavigation();
    } else {
      if (scrolly > 100) {
        setDarkNavbar("navbar__bg-nav-in shadow");
        handleNavigation();
      } else {
        setDarkNavbar("navbar__bg-nav-out");
      }
    }

  }, [scrolly, screenWidth, handleNavigation]);
  return (
    <>
      <nav
        className={`navbar fixed-top navbar-expand-sm navbar-dark p-0 ${darkNavbar} ${showNav}`}
      >
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={logoHogar} alt="Logo Hogar del Niño" width="75" height="75" />
          </Link>

          <button
            className="navbar-toggler"
            onClick={() => {
              setExpanded(
                expanded.includes("show")
                  ? "navbar-collapse collapse"
                  : "navbar-collapse collapse show"
              );

              setDarkNavbar("navbar__bg-nav-in shadow");
            }}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className={expanded} id="navbarNav">
            <div className="mx-auto"></div>

            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink
                  onClick={handleExpanded}
                  activeClassName="active"
                  className="nav-link text-white"
                  exact
                  to="/"
                >
                  Inicio
                </NavLink>
              </li>

              {/* <li className="nav-item">
                <NavLink
                  onClick={handleExpanded}
                  activeClassName="active"
                  className="nav-link text-white"
                  exact
                  to="#"
                >
                  Blog
                </NavLink>
              </li> */}
              <li className="nav-item">
              <a
  onClick={handleExpanded}
  className="nav-link text-white"
  href="https://ee.kobotoolbox.org/x/nDombz8S"
  target="_blank"
  rel="noopener noreferrer"
>
  Solicitud ingreso
</a>

              </li>
              {/* <li className="nav-item">
                <NavLink
                  onClick={handleExpanded}
                  activeClassName="active"
                  className="nav-link text-white"
                  exact
                  to="/about"
                >
                  About
                </NavLink>
              </li> */}
              <li className="nav-item">
                <NavLink
                  onClick={handleExpanded}
                  activeClassName="active"
                  className="nav-link text-white"
                  exact
                  to="/contact"
                >
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};
