import React, { lazy } from "react";
import { Route, Switch } from "react-router";
import { Footer } from "../components/ui/footer/Footer";
import { Navbar } from './../components/ui/navbar/Navbar';
import { Suspense } from "react";
import { LoadingSpinner } from './../components/ui/loading/LoadingSpinner';


const HomeScreen = lazy(() => import('../screens/HomeScreen'));
const BlogScreen = lazy(() => import('../screens/BlogScreen'));
const ContactScreen = lazy(() => import('../screens/ContactScreen'));
const IncomeAppScreen = lazy(() => import('../screens/IncomeAppScreen'));


export const MainRouter = () => {

  return (
    <>
      <Navbar />
      <Suspense fallback={<LoadingSpinner/>}>
        <Switch>
          <Route exact={true} path="/" component={HomeScreen} />
          <Route exact={true} path="/hogar/:info" component={HomeScreen} />
          <Route exact={true} path="/blog" component={BlogScreen} />
          <Route exact={true} path="/contact" component={ContactScreen} />
          <Route exact={true} path="/solicitud" component={IncomeAppScreen} />
        </Switch>
      </Suspense>
      <Footer />
    </>
  );
};
