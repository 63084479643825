import React from 'react';
import ReactDOM from 'react-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import './styles/styles.scss';
import { HogarApp } from './HogarApp';

ReactDOM.render(
    <HogarApp/>,
  document.getElementById('root')
);
