import React from "react";

export const LoadingSpinner = () => {
  return (
    <div className="custom-loading text-center w-100 vh-100">
      <div className="spinner-border custom-spinner text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};
