import React, { Suspense } from "react";
import { AppRouter } from "./routers/AppRouter";
import { LoadingSpinner } from "./components/ui/loading/LoadingSpinner";

export const HogarApp = () => {
  return (
      <Suspense fallback={<LoadingSpinner />}>
        <AppRouter />
      </Suspense>
  );
};