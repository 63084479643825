import React from "react";
import { Link } from "react-router-dom";
import { LazyImage } from './../loading/LazyImage';
import logoHogar from "../../../assets/logos/programs/hogar-del-nino.svg";
import logoninos from "../../../assets/logos/ninos.png";

export const Footer = () => {

  const imgNinosLogo = {
    src:logoninos,
    alt:"logo pbo",
    height:"70",
    width:"70",
  };

  const logoHdn = {
    src:logoHogar,
    alt:"Logo Visa",
    width:"150",
    height:"150",
    className:"img-fluid"
  }

  return (
    <div className="d-grid gap-2 footer__bg-footer fancy-border  ">
      <div className=" d-grid p-5 footer__bgs-footer fancy-border-bottom">
        <div className="  container">
          <div className=" row">
            <div className=" col-md-4 col-12 text-center ">
              <p className="fs-4 pt-md-2">Add Impact to your Inbox</p>
            </div>
            <div className="col-md-8 col-12">
              <div className="d-flex justify-content-evenly flex-row flex-wrap">
                <div className="row">
                  <div className="col-12 col-md-3 p-md-0 m-md-0 m-1 input-group-lg">
                    <input
                      type="text"
                      className="form-control border-primary fs-normal"
                      placeholder="First Name"
                    />
                  </div>
                  <div className=" col-12 col-md-3 p-md-0 m-md-0 m-1 input-group-lg">
                    <input
                      type="text"
                      className="form-control border-primary fs-normal"
                      placeholder="Last Name"
                    />
                  </div>
                  <div className="col-12 col-md-3 p-md-0 m-md-0 m-1 input-group-lg">
                    <input
                      type="text"
                      className="form-control border-primary fs-normal"
                      placeholder="Email"
                    />
                  </div>

                  <div className="col-12 col-md-3 p-md-0 m-md-0 m-1 input-group-lg">
                    <div className="d-grid">
                      <button type="button" className="btn btn-primary btn-lg">
                        Suscribe
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" d-grid mb-5 ">
        <div className="container">
          <div className="row">
            <div className=" col-12 col-md-3 p-2 border-end">
              <p className="text-center d-grid fs-normal">
                <span>Patronato Benefico Oriental</span>
                <span>info@pbo.org.do </span>
                <span>809-523-8901 - 809-556-3181</span>
                <span>Avenida Libertad #5 La Romana, República Dominicana</span>
              </p>
            </div>
            <div className=" col-12 col-md-3 p-2 border-end">
              <p className="text-center d-grid fs-normal">
                <span>Hogar del Niño</span>
                <span>hogardelnino@pbo.org.do</span>
                <span>(809) 556-3682</span>
                <span>
                  Calle N, esq. Carretera Romana-Higueral, Preconca Nueva, La Romana, República Dominicana.
                </span>
              </p>
            </div>
            <div className="col-12 col-md-2 text-center pt-md-2 border-end align-self-center">
            <LazyImage image={logoHdn} />
            </div>

            <div className="col-12 col-md-4">
              <div className=" row">
                <div className=" col-12">
                  <div className=" d-flex flex-grap justify-content-evenly flex-row pt-md-0 pt-5">
                    <Link to={{ pathname: "https://www.facebook.com/PBOorg" }} target="_blank">
                      <i className="bi bi-facebook footer__icons"></i>
                    </Link>
                    <Link to={{ pathname: "https://www.instagram.com/pboorg/" }} target="_blank">
                      <i className="bi bi-instagram footer__icons"></i>
                    </Link>
                    <Link to={{ pathname: "https://www.youtube.com/channel/UCAKN7DzLD16E11bgBA7qFsg" }} target="_blank">
                      <i className="bi bi-youtube footer__icons"></i>
                    </Link>
                  </div>
                </div>
                <div className=" col-12 ">
                  <div className="text-center p-1">
                     <LazyImage image={imgNinosLogo} />
                    <p className="m-0 p-0 fs-normal">
                      Hogar del Niño © 2022. Todos los derechos reservados.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
