import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { MainRouter } from "./MainRouter";

export const AppRouter = () => {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/" component={MainRouter} />
        </Switch>
      </div>
    </Router>
  );
};
