import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export const LazyImage = ({ image }) => {
    return (
        <LazyLoadImage
          alt={image.alt}
          src={image.src}
          height={image.height}
          width={image.width}
          className={image.className}
          effect="blur"
          />    
    )
}
